import React, {ReactNode, createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import {decode as base64_decode} from 'base-64'

const TokenContext = createContext<{
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
} | null>(null);

export function useToken() {
  return useContext(TokenContext);
}


export function TokenProvider({ children }: { children: ReactNode }) {
    const [token, setToken] = useState<string | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

  

  const fetchToken = async (username: string, password: string) => {
    let data = qs.stringify({
      grant_type: "client_credentials",
      scope: "openid",
      client_id: process.env.REACT_APP_CLIENT_ID,
      username: username,
      password: password,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_TOKEN_ENDPOINT,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      setToken(response.data.access_token);
      sessionStorage.setItem('bid_token', response.data.access_token)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const SEPARATOR = '&';
    if (window.location.pathname === "/token-login") {
      let params = base64_decode(new URLSearchParams(window.location.search).get('token')!);
      const parts = params.split(SEPARATOR);
      fetchToken(parts[0], parts[1]);
      navigate(location.pathname);
    }
  }, [location.pathname]);

  return (
    <TokenContext.Provider value={{ token, setToken }}>
      {children}
    </TokenContext.Provider>
  );
}