import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const oidcConfig = {
  authority: `${process.env.REACT_APP_AUTHENTIK_CONFIG}`,
clientId: `${process.env.REACT_APP_CLIENT_ID}`,
redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
scope: 'openid',
grantType: 'client_credentials',
automaticSilentRenew: false,
autoSignIn: false
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(

      <App />

);
