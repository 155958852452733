import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import "./App.css";
import { GlobalStateProvider } from "./components/GlobalStateProvider";
import Home from "./pages/Home";
import OffersActive from "./pages/Offers/OffersActive";
import OffersFinished from "./pages/Offers/OffersFinished";
import OffersPaused from "./pages/Offers/OffersPaused";
import { TokenProvider } from "./TokenContext";
import Allowlist from "./pages/Whitelist";
import { Bid } from "./components/Shipment/Bid/Bid"




export default function App() {
  return (
    <BrowserRouter>
      <GlobalStateProvider>
        <TokenProvider>
          {/* Wrap only components that need the useToken hook */}
          <Navbar title={"Navbar"}>
            <Routes>
              <Route path="/token-login" element={<Home />} />
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/allowlist" element={<Allowlist />} key="allowlist" />
              <Route path="/offers/active" element={<OffersActive />} />
              <Route path="/offers/paused" element={<OffersPaused />} />
              <Route path="/offers/finished" element={<OffersFinished />} />
            </Routes>
          </Navbar>     
        </TokenProvider>
      </GlobalStateProvider>
    </BrowserRouter>
  );
}
